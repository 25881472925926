import React from "react";
import { imgConvertToWebP } from "../../utils";

class MobileBackground extends React.Component {
  render() {
    /* opacity-60 */
    const { imgMobUrl, bgVisible, children, opacity } = this.props;
    return (
      <div className="relative min-h-[70vh] bg-black">
        {children}
        <img
          alt="mobile-background"
          src={imgConvertToWebP(imgMobUrl).src}
          className={`${
            bgVisible ? "block md:hidden" : "hidden"
          } absolute top-0 z-0 min-h-[70vh] w-full object-contain object-top ${
            opacity ? "opacity-" + opacity : ""
          }`}
        />
      </div>
    );
  }
}

export default MobileBackground;
