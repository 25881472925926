import React from "react";
import { imgConvertToWebP } from "../../utils";

class Player extends React.Component {
  render() {
    const { imgUrl, children, opacity } = this.props;

    console.log(imgUrl);

    /* opacity-60 */
    return (
      <div className="relative min-h-[70vh] bg-black">
        {children}
        <img
          alt="background"
          src={imgConvertToWebP(imgUrl).src}
          className={`hidden md:block absolute top-0 z-0 min-h-[70vh] w-full object-contain object-top ${
            opacity ? "opacity-" + opacity : ""
          }`}
        />
      </div>
    );
  }
}
export default Player;
