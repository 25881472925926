import React from "react";
import { imgConvertToWebP } from "../../utils";

const BlocImage = ({
  src,
  alt,
  className,
  animate = "fade-left",
  width = "1920",
  height = "1080",
  mode = "limit",
}) => {
  const { src: webpSrc, srcSet } = imgConvertToWebP(src, width, height, mode);
  const sources = srcSet.split(", ").map((src) => {
    const [srcUrl, srcSize] = src.split(" ");
    return (
      <source type="image/webp" srcSet={srcUrl} sizes={srcSize} key={srcUrl} />
    );
  });

  return (
    <picture className={className} alt={alt}>
      {sources}
      <img
        {...(animate !== "None" ? { "data-aos": animate } : {})}
        src={webpSrc}
        alt={alt}
      />
    </picture>
  );
};

export default BlocImage;
