import * as React from "react";
import { graphql } from "gatsby";
import BlocTitle from "../components/elements/bloc-title";
import BlocImage from "../components/elements/bloc-image";
import BlocContent from "../components/elements/bloc-content";
import TextBackground from "../components/elements/text-background";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import MobileBackground from "../components/elements/mobile-background";

const BenefitPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiBenefit.SEO.metaTitle}</title>
        <meta
          name="description"
          content={data.strapiBenefit.SEO.metaDescription}
        />

        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className=" bg-black font-gotham text-white">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <BlocTitle
            styleName="text-xl lg:text-3xl font-gotham-ultra"
            content={data.strapiBenefit.title}
          />
        </div>
        <div>
          <div>
            <BlocImage
              animate="false"
              className="h-20 object-cover sm:h-auto flex flex-wrap"
              src={data.strapiBenefit.banner.url}
              width="1920"
              height=""
              mode="limit"
            />
          </div>
          <TextBackground imgUrl={data.strapiBenefit.imgBackground.url}>
            <MobileBackground
              imgMobUrl={data.strapiBenefit.mobileBackground.url}
              bgVisible={data.strapiBenefit.imgBackgroundVisible}
            >
              <div className="relative z-30 m-auto max-w-screen-md p-10">
                <BlocContent
                  className="pb-10"
                  content={data.strapiBenefit.event.data.event}
                />
                <BlocContent
                  content={data.strapiBenefit.branding.data.branding}
                />
              </div>
            </MobileBackground>
          </TextBackground>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query strapiBenefitQuery {
    strapiBenefit {
      title
      banner {
        url
      }
      event {
        data {
          event
        }
      }
      branding {
        data {
          branding
        }
      }
      imgBackground {
        url
      }
      imgBackgroundVisible
      SEO {
        metaTitle
        metaDescription
      }
      mobileBackground {
        url
      }
    }
  }
`;

export default BenefitPage;
