// src/utils.js
export function imgConvertToWebP(
  url,
  width = "1920",
  height = "1080",
  mode = "limit"
) {
  if (!url) return "";

  const urlParts = url.split("/");
  const indexOfUpload = urlParts.indexOf("upload");

  if (indexOfUpload === -1) return url;

  const fileName = urlParts[urlParts.length - 1].split(".")[0];
  const scale = mode === "scale" ? ",g_faces" : "";

  const breakpoints = [1920, 1600, 1440, 1280, 640, 320];
  const srcSet = breakpoints
    .filter((breakpoint) => width > breakpoint)
    .map((w) => {
      const h = (w * height) / width;
      return `${urlParts
        .slice(0, indexOfUpload + 1)
        .join("/")}/f_webp,c_${mode},w_${w}${
        h ? `,h_${h}` : ""
      }${scale}/${fileName}.webp ${w}w`;
    })
    .join(", ");

  urlParts.splice(
    indexOfUpload + 1,
    0,
    `f_webp,c_${mode},w_${width}${height ? `,h_${height}` : ""}${scale}`
  );
  return {
    src: `${urlParts.slice(0, indexOfUpload + 2).join("/")}/${fileName}.webp`,
    srcSet:
      srcSet ||
      `${urlParts
        .slice(0, indexOfUpload + 2)
        .join("/")}/${fileName}.webp ${width}w`,
  };
}
export function imgConvertToMp4(url, width = "1920", height = "1080") {
  if (!url) return "";

  const urlParts = url.split("/");
  const indexOfUpload = urlParts.indexOf("upload");

  if (indexOfUpload === -1) return url;

  urlParts.splice(indexOfUpload + 1, 0, `f_mp4,w_${width},h_${height}`);
  return urlParts.join("/");
}
